<template>
	<div class="goodsList">
		<!-- <div class="ad">
			<img class="regular" src="../../assets/sources/banner.png">
		</div> -->
		
		<div class="filter">
			<div class="main">
				<div class="options">
					<div class="item" @click="currentCate = $route.query.cateId">{{$t('tip.qb')}}</div>
					<div class="devide"></div>
					<template v-for="val in sons">
						<div class="item" @click="currentCate = val.categoryId">{{val.categoryName}}</div>
						<div class="devide"></div>
					</template>
					<!-- <div class="devide"></div> -->
				<!-- 	<div class="item">排列方式</div>
					<div class="devide"></div>
					<div class="item">筛选器</div>
					<div class="item">颜色</div>
					<div class="item">尺寸</div>
					<div class="item">系列</div> -->
				</div>
				<!-- <div class="total">{{total}}{{$t('cart.unit')}}{{$t('cart.good')}}</div> -->
			</div>
		</div>
		<div class="list">
			<div class="item" v-for="val in list" :key="val.id">
				<GoodsItem :detail="val"></GoodsItem>
			</div>
		</div>
		<div class="more" @click="loadMore" v-if="list.length > 0">{{$t('nav.more')}}</div>
	</div>
</template>

<script>
import GoodsItem from '../../components/Goods/listItem.vue'
import Api from '../../api/request.js'
export default {
	name: 'GoodsNews',
	components: {
		GoodsItem
	},
	data() {
		return {
			page:{
				page: 1,
				size: 24
			},
			list:[],
			total: 0,
			currentCate: '',
			sons:[]
		};
	},

	created(){
		this.type = this.$route.query.type;
		console.log('----type------------', this.type)
		this.findChild();
		this.getList()
	},
	methods:{
		findChild(){
			this.sons = this.$store.state.cateList.find(item=>{
				return item.categoryId == this.$route.query.cateId;
			});
			this.sons = this.sons?this.sons.sons:[];
		},
		getList(){
			//this.$loading.start();
			
			// Api.Goods.list({
			// 	categoryId: this.currentCate,
			// 	page: this.page.page,
			// 	pageLimit: this.page.size,
			// }).then(res=>{
			// 	if(this.page.page == 1){
			// 		this.list = res.data.data.list;
			// 		this.total = res.data.data.totalLimits
			// 	}else{
			// 		if(res.data.data.list.length == 0){
			// 			this.$dialog.message(this.$t('tip.noMore'));
			// 		}else{
			// 			this.list = this.list.concat(res.data.data.list);
			// 		}
			// 	}
			// 	this.$loading.done();
			// })

			if(this.type == 1){
				Api.Goods.list({
					label_id: 5,
					page: this.page.page,
					page_limit: this.page.size,
				}).then((res) => {
					if(this.page.page == 1){
						this.list = res.data.data.list;
						this.total = res.data.data.totalLimits
					}else{
						if(res.data.data.list.length == 0){
							this.$dialog.message(this.$t('tip.noMore'));
						}else{
							this.list = this.list.concat(res.data.data.list);
						}
					}
				});
			}
			if(this.type == 2) {
				Api.Goods.list({
					label_id: 3,
					page: this.page.page,
					page_limit: this.page.size,
				}).then((res) => {
					//this.list = res.data.data?res.data.data.list:[];
					if(this.page.page == 1){
						this.list = res.data.data.list;
						this.total = res.data.data.totalLimits
					}else{
						if(res.data.data.list.length == 0){
							this.$dialog.message(this.$t('tip.noMore'));
						}else{
							this.list = this.list.concat(res.data.data.list);
						}
					}
				});
			}

		},
		loadMore(){
			this.page.page += 1;
			this.getList();
		}
	}
};
</script>

<style lang="less" scoped>
.ad{
	height: 540px;
}
.cate{
	font-size: 28px;
	font-weight: bold;
	line-height: 40px;
	margin: 30px 0;
	text-align: center;
}
.filter{
	padding: 0 7px;
	.main{
		height: 56px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
		.options{
			display: flex;
			align-items: center;
			.item{
				font-size: 14px;
				padding: 0 30px;
				cursor: pointer;
			}
			.devide{
				&:first-child{
					display: none;
				}
				&+.item{
					padding: 0 40px;
				}
				width: 1px;
				height: 30px;
				background: #BABABA;
			}
		}
	}
	.total{
		font-size: 14px;
		margin-right: 40px;
	}
}
.list{
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 60px 7px;
	.item {
		width: calc((100vw - 32px) / 4);
		margin: 50px 6px 0 0;
	}
}
.more{
	height: 50px;
	line-height: 50px;
	text-align: center;
	border: 2px solid #BBB;
	margin: 0 21px 84px;
	font-size: 16px;
	cursor: pointer;
}
@media screen and (max-width: 500px) {
.ad{
	height: 28.1vw;
}
.cate{
	font-size: 4vw;
	font-weight: bold;
	margin: 4vw 0;
	text-align: center;
}
.filter{
	padding: 0 7px;
	.main{
		height: 12vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #757575;
		border-bottom: 1px solid #757575;
		.options{
			width: 100%;
			display: flex;
			align-items: center;
			.item{
				font-size: 3vw;
				padding: 0 !important;
				flex: 1;
				text-align: center;
			}
			.devide{
				display: none;
			}
		}
	}
	.total{
		display: none;
		font-size: 14px;
		margin-right: 40px;
	}
}
.list{
	display: flex;
	flex-wrap: wrap;
	padding: 0 0 6vw 6px;
	.item {
		width: calc((100vw - 18px) / 2);
		margin: 6px 6px 0 0;
	}
}
.more{
	height: 12vw;
	line-height: 13vw;
	border: 1px solid #BBB;
	font-size: 4vw;
}
}
</style>
